.dropdowns {
  position: relative;
}

.dropdowns-menu {
  min-width: 200px;
  min-height: 200px;
  position: absolute;
  left: -125px;
  top: calc(100% + 0.4rem);
  background-color: white;
  padding: 0.95rem;
  border-radius: 0.25rem;
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transform: translateY(-10px);
  transition:
    opacity 150ms ease-in-out,
    transform 150ms ease-in-out;
}

.dropdowns-menu.active {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
  z-index: 10 !important;
}

.dropdowns {
  accent-color: #6640d7;
}

/* Motivation slider */

.range-slider {
  height: 5px;
  position: relative;
  background-color: #e1e9f6;
  border-radius: 2px;
  margin-top: 20px;
}

.range-selected {
  height: 100%;
  left: 30%;
  right: 30%;
  position: absolute;
  border-radius: 5px;
  background-color: #6640d7;
}

.range-input {
  position: relative;
}

.range-input input {
  position: absolute;
  width: 100%;
  height: 5px;
  top: -7px;
  background: none;
  pointer-events: none;
  -webkit-appearance: none;
}

.range-input input::-webkit-slider-thumb {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 3px solid #6640d7;
  background-color: #fff;
  cursor: pointer;
  pointer-events: auto;
  -webkit-appearance: none;
}

.accordion-item {
  border: transparent !important;
  border-bottom: 1px solid rgba(128, 128, 128, 0.187) !important;
}

.field {
  margin: 2px 0 10px 2px;
  display: flex;
  align-items: center;
  gap: 0.6rem;
}
.field input {
  cursor: pointer;
}
.field label {
  cursor: pointer;
}

.accordion-item .field input[type='checkbox'] label {
  cursor: pointer;
}

.accordion-button:not(.collapsed) {
  background-color: transparent !important;
  font-weight: bold;
}

.accordion-button:focus {
  border-color: transparent !important;
}
.fondi {
  font-size: 16px;
  margin-left: 4px;
}

.radios input {
  cursor: pointer;
}

.radios label {
  cursor: pointer;
}
