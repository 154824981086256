@font-face {
  font-family: 'Fixel Display';
  src: url('./fonts/FixelDisplay-SemiBold.woff2') format('woff2');
}
@font-face {
  font-family: 'Fixel Text-SemiBold';
  src: url('./fonts/FixelText-SemiBold.woff2') format('woff2');
}
@font-face {
  font-family: 'Fixel Text';
  src: url('./fonts/FixelText-Regular.woff2') format('woff2');
}

/*
=============== 
Variables
===============
*/

:root {
  --clr-bg-primary: linear-gradient(180deg, #131836 0%, #080d27 48.96%, #020a26 100%);
  --clr-btn-primary: linear-gradient(90deg, #455eb6 0%, #5d42d1 43.75%, #6640d7 100%);
  --clr-white: #ffff;
  --text-color-main: #333333;
  --text-purple: #6640d7;
}

* {
  font-family: 'Quicksand', Arial, Helvetica, sans-serif;
  /* overflow: hidden; */
}

.App {
  background: var(--clr-bg-primary);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
}

footer {
  position: sticky;
  top: 100%;
  background: linear-gradient(180deg, #020a26 100%);
}

.btn-prim {
  background: var(--clr-btn-primary);
}

.btn-prim:hover {
  background: linear-gradient(270deg, #455eb6 0%, #5d42d1 43.75%, #6640d7 100%);
  box-shadow: 1px 1px 8px 1px #455eb652;
}

.btn-prim:active {
  transform: scale(0.97);
}

.form-check-input {
  border-radius: 0px;
}

.btn-prim:hover {
  background: linear-gradient(270deg, #455eb6 0%, #5d42d1 43.75%, #6640d7 100%);
  box-shadow: 1px 1px 8px 1px #455eb652;
}

.btn-prim:active {
  transform: scale(0.97);
}

.form-check-input {
  border-radius: 0px;
}

.column-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
}

.dashboard-wrapper {
  min-height: 76vh;
  background-color: var(--clr-white);
}

.text-main-dark {
  color: var(--text-color-main);
}

.text-main-secondary {
  color: var(--text-purple);
}

.newHackathon-copy {
  cursor: pointer;
  transition: all 0.1s ease;
}
.newHackathon-copy:hover {
  transform: translateY(2px);
}

.newHackathon-copy:active {
  color: var(--text-purple);
  transform: translateX(2px);
}

.singleHackathon-wrapper {
  min-height: 77vh;
  background-color: var(--clr-white);
  width: 100%;
}

.participants-table {
  overflow-x: auto;
  scroll-snap-type: mandatory;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background-color: white;
}

::-webkit-scrollbar-thumb {
  background-color: var(--text-purple);
  border-radius: 14px;
}

.icon {
  width: 20px;
}

/* filter pills */
.rounded-pill:hover {
  transform: scale(1.06);
}

.rounded-pill:active {
  transform: scale(0.97);
}

.remove-icon:hover {
  cursor: pointer;
}

.columns-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.single-column {
  flex: 0 0 48%;
  padding: 4px;
}
