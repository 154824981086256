.column {
  border-radius: 10px;
  background: linear-gradient(90deg, #455eb642 0%, #5c42d173 43.75%, #6640d777 100%);
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 1rem;
  /* min-height: 100px; */
  padding: 1rem;
  text-align: center;
  max-height: 150px;
}
