.guideline-element {
  border-radius: 8px;
  position: fixed;
  width: 140px;
  height: 40px;
  bottom: 2.5em;
  right: 3em;
  cursor: pointer;
  z-index: 1;
  background: linear-gradient(90deg, #455eb6 0%, #5d42d1 43.75%, #6640d7 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 1px 8px 1px #455eb652;
}

.guideline-icon {
  color: white;
  padding: auto;
}

.guideline-icon h4 {
  font-size: 19px;
}

.guideline-element:hover {
  background: linear-gradient(270deg, #455eb6 0%, #5d42d1 43.75%, #6640d7 100%);
  box-shadow: 1px 1px 8px 1px #455eb652;
}

.guideline-element:active {
  transform: scale(0.97);
}

/* ---------------------------- */
.dropdowns {
  position: relative;
}

.dropdowns-menuu {
  min-width: 210px;
  position: absolute;
  bottom: calc(100% + 0.16rem);
  right: 1px;
  background-color: white;
  padding: 0.6rem;
  padding-bottom: 0.5px;
  border-radius: 0.25rem;
  box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transform: translateY(-10px);
  transition:
    opacity 150ms ease-in-out,
    transform 150ms ease-in-out;
}

.dropdowns-menuu p {
  font-weight: bolder;
  color: #6640d7;
}

.dropdowns-menuu.active {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
  z-index: 1;
}
